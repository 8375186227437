import classnames from "classnames";
import { InferType, object, string } from "yup";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect, useContext, FC, useCallback, PropsWithChildren } from "react";
import Countdown from "react-countdown";
import { AuthContext, ClientBFFContext, useTranslation } from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import Button from "components/base/button/Button";
import { Svg } from "components/base/svg/Svg";
import VerificationCodeInput from "./code-input/VerificationCodeInput";
import styles from "./verification-item.module.sass";

const validationSchema = object({
    code: string().required().min(6),
});

interface IVerificationItemProps {
    type: "email" | "phone";
    verified: boolean;
}

type IVerificationItemForm = InferType<typeof validationSchema>;

const VerificationItem: FC<PropsWithChildren<IVerificationItemProps>> = ({
    children,
    type,
    verified,
}) => {
    const client = useContext(ClientBFFContext);
    const { t } = useTranslation();
    const { setToken } = useContext(AuthContext);
    const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(verified);
    const [loading, setLoading] = useState(false);
    const [tempVerificationId, setTempVerificationId] = useState("");
    const [validUntil, setValidUntil] = useState<number>();

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: { isValid, isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            code: "",
        },
    });

    useEffect(() => {
        if (isValid) {
            clearErrors();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    const onSubmit = useCallback(
        async (data: IVerificationItemForm) => {
            if (isValid && !isSubmitting && !loading) {
                const req = {
                    id: tempVerificationId,
                    code: Object.values(data).join(""),
                };
                if (type === "email") {
                    try {
                        const response = await client.checkEmail(req);

                        if (response.token) {
                            setToken(response.token);
                        }
                    } catch (err: any) {
                        const error = err.response?.data;

                        if (error.error === "registration_error") {
                            setError("root.apiError", {
                                type: "wrongCode",
                                message: t("verification_error_wrongCode"),
                            });
                        }
                    }
                } else if (type === "phone") {
                    try {
                        const response = await client.checkPhone(req);

                        if (response.token) {
                            setToken(response.token);
                        }
                    } catch (err: any) {
                        const error = err.response?.data;

                        if (error.error === "registration_error") {
                            setError("root.apiError", {
                                type: "wrongCode",
                                message: t("verification_error_wrongCode"),
                            });
                        }
                    }
                }
            }
        },
        [client, isSubmitting, isValid, loading, setError, setToken, t, tempVerificationId, type],
    );

    const handleRequestCode = useCallback(async () => {
        if (!isSubmitting && !loading) {
            setLoading(true);
            if (type === "email") {
                const response = await client.validateEmail();

                setTempVerificationId(response.id);
                setValidUntil(new Date(response.valid_until.toString()).getTime());
                setIsVerificationCodeSent(true);
                setLoading(false);
            } else if (type === "phone") {
                const response = await client.validatePhone();

                setTempVerificationId(response.id);
                setValidUntil(new Date(response.valid_until.toString()).getTime());
                setIsVerificationCodeSent(true);
                setLoading(false);
            }
        }
    }, [client, isSubmitting, loading, type]);

    const renderContent = useCallback(({ minutes, seconds, completed }: any) => {
        if (completed) {
            return null;
        }

        return (
            <p className={styles.resendTxt}>
                <Translate
                    tid="verification_newCodeResend"
                    replace={{
                        minutes,
                        seconds,
                    }}
                />
            </p>
        );
    }, []);

    const onCompleteHandler = () => {
        setIsVerificationCodeSent(false);
    };

    return (
        <div className={classnames(styles.block, type)}>
            {!verified ? (
                <>
                    {children}
                    <form className={styles.verifyForm} onSubmit={handleSubmit(onSubmit)}>
                        <span>
                            <Translate tid="verification_verificationCode" />:
                        </span>
                        <div className={styles.verifyFormFields}>
                            <Controller
                                render={({ field: { onChange, value } }) => (
                                    <VerificationCodeInput
                                        codeLength={6}
                                        variant={type}
                                        error={errors.root?.apiError as FieldError}
                                        value={value}
                                        disabled={!isVerificationCodeSent}
                                        onChange={onChange}
                                    />
                                )}
                                control={control}
                                name="code"
                            />

                            {!isVerificationCodeSent && (
                                <Translate tid="verification_requestCode" dangerous>
                                    <Button
                                        type="button"
                                        disabled={isSubmitting || loading}
                                        className={styles.requestCodeBtn}
                                        onClick={handleRequestCode}
                                    />
                                </Translate>
                            )}
                            {isVerificationCodeSent && (
                                <Button
                                    type="submit"
                                    disabled={!isValid || isSubmitting || loading}
                                    className={styles.verifyBtn}>
                                    <Translate tid="verification_verify" />
                                </Button>
                            )}
                        </div>
                        {errors.root?.apiError && (
                            <p className={styles.wrongTxt}>{errors.root.apiError.message}</p>
                        )}
                        {isVerificationCodeSent && (
                            <Countdown
                                date={validUntil}
                                renderer={renderContent}
                                onComplete={onCompleteHandler}
                            />
                        )}
                    </form>
                </>
            ) : (
                <section
                    className={styles.successfullyVerified}
                    title={t(`verification_${type}SuccessMessage`)}>
                    <Svg
                        className={styles.iconVerified}
                        src="/common/desktop/base-icons/account-verified.svg"
                        wrapper="span"
                    />
                    <Translate tid={`verification_${type}SuccessMessage`} />
                </section>
            )}
        </div>
    );
};
export default VerificationItem;
