import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ITokenResponse } from "@finbackoffice/clientbff-client";
import { RequestError, formatAppNameText } from "@finbackoffice/fe-core";
import classnames from "classnames";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    ClientBFFContext,
    ITranslateProps,
    loginFormSchema,
    ModalsContext,
    useRuntimeConfig,
    useSignupConfig,
    useTranslation,
} from "@finbackoffice/site-core";
import { useRouter } from "next/router";
import { InferType } from "yup";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { SignupInputFieldName } from "@finbackoffice/enums";
import { useDate } from "hooks";
import Translate from "components/base/translate/Translate";
import Button from "components/base/button/Button";
import { LoginViewType } from "components/header/Header";
import ToggleSwitch from "components/base/toggle-switch/ToggleSwitch";
import Img from "components/base/img/Img";
import Loading from "components/base/loading/Loading";
import PhoneInput from "components/base/phone-input/PhoneInput";
import { Svg } from "components/base/svg/Svg";
import Input from "components/base/input-field/Input";
import LoginTwoFactorForm from "../2fa/LoginTwoFactorForm";
import styles from "./login-form.module.sass";

interface ILoginFormProps {
    setCurrentView: (val: LoginViewType) => void;
}

const LoginForm: FC<ILoginFormProps> = ({ setCurrentView }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const router = useRouter();
    const client = useContext(ClientBFFContext);
    const { clearCurrentModal, loginModalRef, signupModalRef } = useContext(ModalsContext);
    const { t } = useTranslation();
    const { formatDate, differenceInYears } = useDate();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [twoFactorToken, setTwoFactorToken] = useState<ITokenResponse | null>(null);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState<ITranslateProps | null>();
    const [excludedErr, setExcludedErr] = useState<ITranslateProps | null>();
    const { signupConfigObj: loginConfigObj } = useSignupConfig([SignupInputFieldName.Password]);

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        formState: { isValid, isSubmitting, errors },
    } = useForm({
        mode: "onChange",
        shouldUnregister: true,
        context: {
            configObj: {
                ...loginConfigObj,
                enableSwitch: COMMON_SITE_CONFIGS.login.enableSwitch,
                t,
            },
        },
        resolver: yupResolver(loginFormSchema),
        defaultValues: {
            login_type: COMMON_SITE_CONFIGS.login.defaultActiveTab,
            phone: "",
            email: "",
            password: "",
        },
    });
    const login_type = COMMON_SITE_CONFIGS.login.enableSwitch
        ? watch("login_type")
        : COMMON_SITE_CONFIGS.login.defaultActiveTab;

    const performLogin = useCallback(async () => {
        clearCurrentModal();

        router.replace(router.asPath);
    }, [router, clearCurrentModal]);

    const onSubmit = useCallback(
        async (data: InferType<typeof loginFormSchema>) => {
            if (isValid && !isSubmitting) {
                setLoading(true);
                const req = {
                    login: data[login_type],
                    password: data.password,
                };

                try {
                    const response = await client.login({
                        login: req.login as string,
                        password: req.password!,
                    });

                    if (response.token) {
                        setLoading(false);
                        if (typeof response.token === "string") {
                            performLogin();
                        } else if (response.token.id) {
                            setTwoFactorToken(response.token);
                        }
                    }
                } catch (err: any) {
                    const statusCode: number = err.response?.status;
                    const error: RequestError & { details: Record<string, string> } =
                        err.response?.data;

                    setLoading(false);
                    if (error.error === "self_excluded_login_error") {
                        differenceInYears(new Date(error.details.blocked_till), new Date()) > 1
                            ? setExcludedErr({ tid: "login_error_self_excluded_permanent" })
                            : setExcludedErr({
                                  tid: "login_error_self_excluded_temporary",
                                  replace: {
                                      blocked_till: formatDate(
                                          new Date(error.details.blocked_till),
                                          "dd MMM yyyy HH:mm:ss",
                                          false,
                                      ),
                                  },
                              });
                        return;
                    }
                    if (error.error === "blocked_error") {
                        setErr({ tid: "login_error_blocked" });
                        return;
                    }
                    if (statusCode === 400 || statusCode === 401 || statusCode === 403) {
                        setErr({ tid: "login_error_mismatch" });
                    }
                }
            }
        },
        [isValid, isSubmitting, login_type, client, performLogin, differenceInYears, formatDate],
    );

    const onSignupPress = useCallback(() => {
        signupModalRef.current?.open();
    }, [signupModalRef]);

    useEffect(() => {
        if (Object.keys(errors).length) {
            clearErrors();
        }
        if (err) {
            setErr(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [login_type]);

    const renderInputs = useMemo(
        () => (
            <>
                {login_type === "email" && (
                    <Controller
                        render={({ field: { onChange, value, name } }) => {
                            return (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    label="login_email"
                                    error={errors.email}
                                    data-testid="account-email-input"
                                    enableAutoComplete
                                    disabled={err?.tid === "login_error_blocked"}
                                />
                            );
                        }}
                        control={control}
                        name="email"
                    />
                )}
                {login_type === "phone" && (
                    <Controller
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                label="userData_mobile"
                                onChange={onChange}
                                testId="account-phone-input"
                                value={value ?? ""}
                                separateDialCode
                                setError={setError}
                                clearErrors={clearErrors}
                                error={errors.phone || (errors.root?.apiError as FieldError)}
                                disabled={err?.tid === "login_error_blocked"}
                            />
                        )}
                        control={control}
                        name="phone"
                    />
                )}
                <Controller
                    render={({ field: { onChange, value, name } }) => {
                        return (
                            <Input
                                onChange={onChange}
                                value={value}
                                name={name}
                                data-testid="account-password-input"
                                type={showPassword ? "text" : "password"}
                                onInnerIconClick={() => setShowPassword(!showPassword)}
                                label="userData_password"
                                placeholder={t("signup_form_password_placeholder", {
                                    replace: {
                                        min_length: loginConfigObj.password?.min_length,
                                        max_length: loginConfigObj.password?.max_length,
                                    },
                                })}
                                innerIconClass={classnames("viewPass", showPassword && "showPass")}
                                error={errors.password}
                                enableAutoComplete
                                disabled={err?.tid === "login_error_blocked"}
                            />
                        );
                    }}
                    control={control}
                    name="password"
                />
            </>
        ),
        [
            clearErrors,
            control,
            err?.tid,
            errors.email,
            errors.password,
            errors.phone,
            errors.root?.apiError,
            login_type,
            setError,
            showPassword,
            loginConfigObj.password?.max_length,
            loginConfigObj.password?.min_length,
            t,
        ],
    );

    const renderLoginContent = useMemo(() => {
        if (excludedErr) {
            return (
                <div className={styles.loginUserBlocked}>
                    <Translate tid={excludedErr.tid} replace={excludedErr.replace} dangerous>
                        <div />
                    </Translate>
                    <Button
                        type="button"
                        variant="secondary"
                        onClick={loginModalRef.current?.close}>
                        <Translate tid={"login_error_self_excluded_button_close"} />
                    </Button>
                </div>
            );
        }
        if (twoFactorToken) {
            return (
                <LoginTwoFactorForm
                    token={twoFactorToken}
                    performLogin={performLogin}
                    close={() => setTwoFactorToken(null)}
                />
            );
        }
        return (
            <form
                onChange={() => setErr(null)}
                onSubmit={handleSubmit(onSubmit)}
                data-testid="account-login-form">
                <div className={styles.loginHeader}>
                    {COMMON_SITE_CONFIGS.login.enableSwitch && (
                        <Controller
                            render={({ field: { onChange, value, name } }) => {
                                return (
                                    <div className="ui-switch-container">
                                        <span
                                            className={classnames(styles.iconGif, {
                                                [styles.active]: value === "email",
                                            })}>
                                            <Svg
                                                src={`/${formatAppNameText(
                                                    COMMON_SITE_CONFIGS.appName,
                                                )}/desktop/login/login-username.svg`}
                                                wrapper="span"
                                                className="svg-icon"
                                            />
                                        </span>
                                        <ToggleSwitch
                                            name={name}
                                            testId="account-email-phone-toggle"
                                            checked={value === "phone"}
                                            onChange={(val) => onChange(val ? "phone" : "email")}
                                            disabled={loading || err?.tid === "login_error_blocked"}
                                        />
                                        <span
                                            className={classnames(styles.iconGif, {
                                                [styles.active]: value === "phone",
                                            })}>
                                            <Img
                                                source={`${ASSETS_URL}/${formatAppNameText(
                                                    COMMON_SITE_CONFIGS.appName,
                                                )}/desktop/login/login-mobile${
                                                    value === "phone" ? "-selected" : ""
                                                }.gif`}
                                                alt="email"
                                                width={16}
                                                height={22}
                                            />
                                        </span>
                                    </div>
                                );
                            }}
                            control={control}
                            name="login_type"
                        />
                    )}
                </div>
                {renderInputs}

                <span className={styles.forgotPass} onClick={() => setCurrentView("forgot")}>
                    <Translate tid="login_forgotPass" />
                </span>
                {err && (
                    <span className={styles.loginError}>
                        <Translate tid={err.tid} replace={err.replace} />
                    </span>
                )}
                <div className={styles.bottomContainer}>
                    <Button
                        type="submit"
                        data-testid="account-submit-button"
                        className={styles.loginBtn}
                        disabled={
                            !isValid || isSubmitting || loading || !!errors.root?.apiError || !!err
                        }>
                        <Translate tid="header_login" />
                    </Button>
                    <Button type="button" variant="secondary" onClick={onSignupPress}>
                        <Translate tid="login_signup" />
                    </Button>
                </div>
            </form>
        );
    }, [
        ASSETS_URL,
        COMMON_SITE_CONFIGS.appName,
        COMMON_SITE_CONFIGS.login.enableSwitch,
        control,
        err,
        errors.root?.apiError,
        excludedErr,
        handleSubmit,
        isSubmitting,
        isValid,
        loading,
        loginModalRef,
        onSignupPress,
        onSubmit,
        performLogin,
        renderInputs,
        setCurrentView,
        twoFactorToken,
    ]);

    return (
        <div className={styles.login}>
            <div>
                {COMMON_SITE_CONFIGS.skinVersion == SkinVersions.Betmidas ? (
                    <Img
                        source={`${ASSETS_URL}/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/desktop/logo.svg`}
                        alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                        title={`${COMMON_SITE_CONFIGS.appName} logo`}
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                    />
                ) : (
                    <Translate tid="login_head" />
                )}
            </div>
            {renderLoginContent}
            {loading && <Loading />}
        </div>
    );
};
export default LoginForm;
